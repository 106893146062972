import fullscreen from './fullScreen';

let rawRatio = 1;
let currentRatio = 1;

const formatRatio = (ratio) => +ratio.toFixed(2);
const resize = () => setScreenResolution(rawRatio);
const compatMac = {
  process (element, document)
  {
    if (fullscreen.isFullscreen)
    {
      document.body.style.height = window.innerHeight + 'px';
      element.style.height = window.innerHeight / currentRatio + 'px';
    }

    !element.isSetScreenResolution && window.addEventListener('resize', resize);
  },
  unload ()
  {
    window.removeEventListener('resize', resize);
  },
  noop ()
  {}
};

/*
*  adaptive screen resolution
*/
export const setScreenResolution = (ratio, document = window.document) =>
{
  const element = document.getElementById('codigger-main');

  if (!element || window.top.electronAPI)
  {
    return;
  }

  const screenWidth = Math.max(screen.width, window.outerWidth);
  const screenHeight = Math.max(screen.height, window.outerHeight);
  const ratioMax = 10;
  const ratioMin = 0.1;
  const grades = [
    {
      width: 1680,
      strategy: (ratio) => formatRatio(ratio)
    },
    {
      width: 1440,
      strategy: (ratio) => formatRatio(ratio - 0.1)
    },
    {
      width: 0,
      strategy: (ratio) => formatRatio(ratio - 0.2)
    }
  ];
  const currentGrade = grades.find((grade) => screenWidth >= grade.width);

  rawRatio = ratio;
  ratio = +ratio || 1;
  ratio = ratio > ratioMax ? ratioMax : (ratio < ratioMin ? ratioMin : ratio);
  ratio = currentGrade.strategy(ratio);
  currentRatio = ratio;

  document.documentElement.style.overflow = 'auto';
  document.documentElement.style.overscrollBehavior = 'none';
  document.body.style.width = screenWidth + 'px';
  document.body.style.height = screenHeight + 'px';
  element.style.width = screenWidth / ratio + 'px';
  element.style.height = screenHeight / ratio + 'px';
  element.style.transformOrigin = '0 0';
  element.style.transform = `scale(${ratio})`;
  element.style.position = 'absolute';
  
  // compat Mac
  compatMac[navigator.userAgent.indexOf('Mac OS') > -1 ? 'process' : 'noop'](element, document);

  // clean
  !element.isSetScreenResolution && element.contentWindow.addEventListener('unload', () =>
  {
    element.isSetScreenResolution = false;
    document.documentElement.style.overflow = '';
    document.documentElement.style.overscrollBehavior = '';
    document.body.style.width = '';
    document.body.style.height = '';
    element.style.width = '';
    element.style.height = '';
    element.style.transformOrigin = '';
    element.style.translate = '';
    element.style.position = '';

    // clean Mac
    compatMac[navigator.userAgent.indexOf('Mac OS') > -1 ? 'unload' : 'noop']();
  });

  // flag
  element.isSetScreenResolution = true;
};
